import { Box, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";

const AppContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  height: "100vh",
  width: "100vw",
  background: "#121212",
});

const InternalDetailsContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  transition: "opacity 5s ease-in-out",
  cursor: "pointer",
  marginTop: "50vh",

  "&:hover": {
    opacity: "0",
  },
});

const HeroText = styled(Typography)({
  fontWeight: "bold",
  fontFamily: "Open Sans",
  fontSize: "1.4rem",
  color: "#c0c0c0",
  marginBottom: "30vh",
});

const Sidekicktext = styled(Typography)({
  fontWeight: "normal",
  fontFamily: "Open Sans",
  fontSize: "0.8rem",
  color: "#c0c0c0",
});

const App = () => {
  // State
  const [loaded, setLoaded] = useState(false);

  //

  // After 3 seconds, set loaded to true
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 3000);
  }, []);

  return (
    <AppContainer>
      <InternalDetailsContainer
        sx={{
          opacity: loaded ? "1" : "0",
        }}
      >
        <HeroText variant="h1">AW</HeroText>

        <Sidekicktext variant="h2">London</Sidekicktext>
      </InternalDetailsContainer>
    </AppContainer>
  );
};

export default App;
